<template>
  <a-spin :spinning="state.page_loading">
    <sdPageHeader title="Fatura Detayları" />
    <Main>
      <sdCards headless>
        <div class="pst">
          <div class="subMenu">
            <span class="blck">
              <sdFeatherIcons
                type="paperclip"
                class="mr-x"
              /> <b class="d-sm-block">Fatura Tarihi</b>
            </span>
            {{ state.details.invoice_date }}
          </div>
          <div class="subMenu">
            <span class="blck">
              <sdFeatherIcons
                type="calendar"
                class="mr-x"
              /> <b>Fatura Seri No</b>
            </span>
            {{ state.details.invoice_serial_number }}
          </div>
          <div class="subMenu">
            <span class="blck">
              <sdFeatherIcons
                type="pie-chart"
                class="mr-x"
              /> <b>Onaylandı mı?</b>
            </span>
            {{ state.details.is_checked }}
          </div>
          <div class="subMenu">
            <span class="blck">
              <sdFeatherIcons
                type="pie-chart"
                class="mr-x"
              /> <b>Tutar</b>
            </span>
            {{ state.details.total }}
          </div>
        </div>
        <hr>
        <div class="pst">
          <div class="subMenu">
            <a-form-item name="generalSearch">
              <a-input
                v-model:value="state.selected.text"
                placeholder="Arama Yap"
              />
            </a-form-item>
          </div>
          <div class="subMenu">
            <a-form-item>
              <a-select
                v-model:value="state.selected.invoice_id"
                placeholder="Fatura Seçiniz"
                :dropdown-match-select-width="false"
              >
                <a-select-option value="">
                  Fatura Seçiniz
                </a-select-option>
                <a-select-option
                  v-for="item in state.incoming_invoices"
                  :key="item.id"
                >
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="subMenu">
            <a-form-item name="rangePicker">
              <a-range-picker
                :value="state.selected.date"
                format="DD/MM/YYYY"
                @change="state.selected.date = $event"
              />
            </a-form-item>
          </div>
          <div class="subMenu">
            <a-form-item>
              <a-select
                v-model:value="state.selected.return"
                placeholder="İade Durumu Seçiniz"
                style="width: 100%"
              >
                <a-select-option value="">
                  İade Durumu
                </a-select-option>
                <a-select-option value="1">
                  İade
                </a-select-option>
                <a-select-option value="2">
                  İade Olmayan
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="subMenu">
            <a-checkbox v-model:checked="state.selected.show_only_price_differences">
              Sadece Fiyat Farkı Olanları Göster
            </a-checkbox>
          </div>
          <div class="subMenu">
            <a-form-item>
              <a-button
                type="primary"
                @click="applyFilter()"
              >
                Filtrele
              </a-button>
            </a-form-item>
          </div>
        </div>
        <hr>
        <a-table
          :data-source="state.rawItems"
          :columns="state.columns"
          row-key="id"
        >
          <template #is_returned_render="{ record }">
            <div v-if="record.is_returned">
              <sdFeatherIcons type="trending-down" />
              İade
            </div>
            <div v-else>
              -
            </div>
          </template>
        </a-table>
      </sdCards>
    </Main>
  </a-spin>
</template>

<script>
import { Main } from '@/view/styled'
import { defineComponent, onMounted, reactive } from 'vue'
import restMethods from '@/utility/restMethods'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ReceiptDetail',
  components: {
    Main,
  },
  setup() {

    const route = useRoute()

    const state = reactive({
      rawItems: [],
      page_loading: true,
      incoming_invoices: [],
      selected: {
        invoice_id: '',
        text: '',
        customer: '',
        date: [],
        return: '',
        show_only_price_differences: false,
      },
      details: {
        invoice_date: 'Tümü',
        invoice_serial_number: 'Tümü',
        is_checked: 'Tümü',
        total: 'Tümü',
      },
      columns: [
        {
          title: 'Müşteri Adı',
          dataIndex: 'title',
          align: 'center',
        },
        {
          title: 'Fatura Tarihi',
          dataIndex: 'invoice_date',
          align: 'center',
        },
        {
          title: 'Fatura Seri No',
          dataIndex: 'invoice_serial_number',
          align: 'center',
        },
        {
          title: 'Firma Desi',
          dataIndex: 'cbm_of_company',
          align: 'center',
        },
        {
          title: 'Firma Tutar',
          dataIndex: 'price_of_company',
          align: 'center',
        },
        {
          title: 'Tsoft Desi',
          dataIndex: 'cbm_of_cargong',
          align: 'center',
        },
        {
          title: 'Tsoft Fiyat',
          dataIndex: 'cost_of_cargong',
          align: 'center',
        },
        {
          title: 'Gönderi No',
          dataIndex: 'request_number',
          align: 'center',
        },
        {
          title: 'İade mi?',
          dataIndex: 'is_returned',
          align: 'center',
          slots: {
            customRender: 'is_returned_render',
          },
        },
        {
          title: 'Oluşturma Tarihi',
          dataIndex: 'created_at',
          align: 'center',
        },
      ],
    })

    const get = () => {
      state.page_loading = true
      restMethods.postForNoc('/get-invoice-rows', { filter: state.selected }).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()

          state.rawItems = data.items

          state.details.invoice_date = data.incoming_invoice_summary.invoice_date
          state.details.invoice_serial_number = data.incoming_invoice_summary.invoice_serial_number
          state.details.is_checked = data.incoming_invoice_summary.is_checked
          state.details.total = data.incoming_invoice_summary.total

          state.incoming_invoices = data.incoming_invoices

        }
        state.page_loading = false
      })

    }

    onMounted(() => {

      state.selected.invoice_id = route.query.invoice_id ?? '' // boş gelirse faturadan bağımszı tüm kayıtlar gösteriliyor

      get()

    })

    const applyFilter = () => {

      get()

    }

    return {
      state,

      applyFilter,
    }

  },

})
</script>

<style>
hr {
  border-color: #ededed;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pst {
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.sub {
  display: block;
}

.subMenu {
  display: inline-block;
  margin-right: 20px; /* Optional: This gives space between items */
  vertical-align: top; /* This makes sure the items are aligned to the top */
}

.pst {
  font-size: 12pt;
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
  margin-bottom: 10px;
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.pstCol {
  font-size: 13pt;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px;
  flex-wrap: nowrap;
  flex-direction: column;
}

.pstCol > div {
  color: #5a5f7d !important;
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.pst-filter {
  flex-direction: row-reverse;
  font-size: 12pt;
  display: flex;
  justify-content: flex-start; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
  margin-bottom: 10px;
}

.pst-filter > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.mr-x {
  margin-right: 10px;
}

.blck {
  display: block;
}

.pddng {
  padding: 0 10px;
}
</style>